const config = {
  production: {
    API_ROOT: "https://api.arnld.ai",
    WS_ROOT: "wss://api.arnld.ai",
    BOT_NAME: "arnldBot",
  },
  development: {
    API_ROOT: "http://localhost:5005",
    WS_ROOT: "ws://localhost:5005",
    BOT_NAME: "arnldTestBot",
  },
};

const environment = process.env.NODE_ENV || "development";
export const { API_ROOT, WS_ROOT, BOT_NAME } = config[environment];
