import React, { useContext, useEffect, useState } from "react";
import { getQrCode, resolveOtp } from "./API";
import Spinner from "../Spinner/Spinner";
import { API_ROOT, BOT_NAME } from "../../const/config";
import { Player } from "@lottiefiles/react-lottie-player";
import errorAnimation from "../../assets/animations/error.json";
import successLoginAnimation from "../../assets/animations/success-star.json";
import additionalLoginAnimation from "../../assets/animations/laptop-login.json";
import AppContext from "../../context";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import styles from "./TelegramClient.module.css";
import TextInput from "../TextInput/TextInput";
import { ArrowRightCircleFill } from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import {
  showQrCode,
  showWelcomeScreen,
  telegramLoginFailed,
  telegramLoginSuccess,
} from "./TelegramClientSlice";

const TelegramClientScreen = () => {
  const { isTelegramLogined, setIsTelegramLogined } = useContext(AppContext);
  const [qrCodeUrl, setQrCodeUrl] = useState(null);
  const [token, setToken] = useState(null);
  const [otp, setOtp] = useState("");
  const [isOTPSubmitting, setIsOTPSubmitting] = useState(false);
  const loginState = useSelector((state) => state.telegram.loginState);
  const otpHint = useSelector((state) => state.telegram.otpHint);
  const errorMessage = useSelector((state) => state.telegram.errorMessage);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchQrCode = async () => {
      const qrResponse = await getQrCode();
      setQrCodeUrl(qrResponse.qr_code_url);
      setToken(qrResponse.token);
    };

    if (isTelegramLogined === false) {
      dispatch(showWelcomeScreen());
      fetchQrCode();
    } else if (isTelegramLogined === true) {
      setIsTelegramLogined(true);
      dispatch(telegramLoginSuccess());
    }
  }, [isTelegramLogined]);

  useEffect(() => {
    if (loginState === "success") {
      setIsTelegramLogined(true);
    }
  }, [loginState]);

  const handle2FASubmit = async () => {
    try {
      setIsOTPSubmitting(true);
      await resolveOtp(otp); // Send OTP to backend
      setTimeout(() => {
        setIsOTPSubmitting(false);
      }, 2000);
    } catch (error) {
      setIsOTPSubmitting(false);
      dispatch(telegramLoginFailed());
    }
  };

  return (
    <TransitionGroup className="transition-container h-100">
      <CSSTransition key={loginState} classNames="fade" timeout={300}>
        <div className="d-flex flex-column position-relative justify-content-center align-items-center h-100">
          {(loginState === "initial" || isTelegramLogined === null) && (
            <Spinner withBG={false} />
          )}

          {loginState === "welcomeScreen" && (
            <div
              className="d-flex flex-column align-content-center justify-content-center my-5"
              style={{ maxWidth: "340px" }}
            >
              <h3 className="mt-0 mb-3" style={{ fontSize: "1.8rem" }}>
                Подключите Телеграм
              </h3>
              <div className={styles.demoVideoContainer}>
                <video
                  src={`${API_ROOT}/static/tg_d.mp4`}
                  autoPlay
                  loop
                  muted
                  playsInline
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "inherit",
                  }}
                />
              </div>
              <p className="text-center" style={{ lineHeight: "1.3" }}>
                После подключения — Айрнольд станет доступен в любом чате.
              </p>
              <div className="w-100 d-flex flex-row justify-content-center">
                <Button
                  onClick={() => dispatch(showQrCode())}
                  variant="filled-rounded"
                >
                  <span
                    className="lh-1"
                    style={{ marginBottom: "2px", fontWeight: "600" }}
                  >
                    Подключить
                  </span>
                  <ArrowRightCircleFill
                    className="ms-2"
                    size={18}
                    color="white"
                    style={{ marginRight: "-3px", marginTop: "-1px" }}
                  />
                </Button>
              </div>
            </div>
          )}

          {loginState === "qrCode" && (
            <div className="d-flex flex-column position-relative justify-content-center align-items-center h-100">
              <div className="mb-5 d-flex flex-column">
                {qrCodeUrl ? (
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <p className="title text-center mb-4">
                      <b>Отсканируйте</b>
                    </p>
                    <img
                      src={`${API_ROOT}${qrCodeUrl}`}
                      className={styles.qrImg}
                      width={180}
                      alt="Войдите в Телеграм"
                    />
                    <p
                      className="text-center mt-4 mb-0"
                      style={{ lineHeight: "1.3" }}
                    >
                      1. Откройте Телеграм с телефона и перейдите в настройки.
                      <br />
                      2. Выберите <b>Устройства</b> &gt;{" "}
                      <b>Подключить устройство</b>.
                      <br />
                      3. Направьте камеру телефона на этот экран.
                    </p>
                  </div>
                ) : (
                  <Spinner withBG={false} />
                )}
              </div>
            </div>
          )}

          {loginState === "otpInput" && (
            <div className="d-flex flex-column align-content-center justify-content-center mb-5 mt-0">
              <Player
                autoplay
                loop
                speed={0.5}
                src={additionalLoginAnimation}
                style={{ width: "160px", height: "160px" }}
              />
              <h3 className="mt-4 mb-2" style={{ fontSize: "1.8rem" }}>
                Введите пароль
              </h3>
              <p className="text-center" style={{ lineHeight: "1.3" }}>
                Ваш аккаунт защищен паролем
              </p>

              <div
                className="position-relative mt-4"
                style={{ margin: "0 -30px", minHeight: "45px", width: "300px" }}
              >
                <TextInput
                  value={otp}
                  setValue={setOtp}
                  onConfirmCB={handle2FASubmit}
                  type={"password"}
                  isLoading={isOTPSubmitting}
                />
              </div>
              {otpHint && (
                <p className={styles.hint} style={{ lineHeight: "1.3" }}>
                  подсказка: {otpHint}
                </p>
              )}
            </div>
          )}

          {loginState === "success" && isTelegramLogined === true && (
            <div
              className="d-flex flex-column align-content-center justify-content-center my-5"
              style={{ maxWidth: "340px" }}
            >
              <Player
                autoplay
                src={successLoginAnimation}
                style={{ width: "160px", height: "160px" }}
              />
              <h3 className="mt-4 mb-3" style={{ fontSize: "1.8rem" }}>
                Телеграм подключен!
              </h3>

              <p className="text-center" style={{ lineHeight: "1.3" }}>
                Вызывайте Айрнольда в любом чате — через команду&nbsp;
                <span className="highlight-text">
                  @{BOT_NAME} [любой текст]
                </span>
              </p>
            </div>
          )}

          {loginState === "failed" && (
            <div
              className="d-flex flex-column align-content-center justify-content-center mb-5 mt-0"
              style={{ maxWidth: "360px" }}
            >
              <Player
                autoplay
                src={errorAnimation}
                style={{ width: "160px", height: "160px" }}
              />
              <h3 className="mt-4 mb-2" style={{ fontSize: "1.8rem" }}>
                Вход не выполнен
              </h3>
              <p className="text-center" style={{ lineHeight: "1.3" }}>
                {errorMessage || "Обновите страницу и попробуйте ещё раз."}
              </p>
            </div>
          )}
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default TelegramClientScreen;
