// ConnectionStatus.js
import React, { useContext } from "react";
import AppContext from "../../context";
import { Button } from "react-bootstrap";
import { ArrowLeft, List } from "react-bootstrap-icons";

import tasksLogo from "../../assets/icons/tasks.svg";
import { Chat, Telegram } from "react-bootstrap-icons";

import styles from "./MenuButton.module.css";

const routeMap = {
  "/m/tasks": {
    icon: <img src={tasksLogo} height="100%" alt="Tasks logo" />,
    title: "Задачи",
  },
  "/m/chat": {
    icon: <Chat color={"#ffffff"} size={18} />,
    title: "Чатик",
  },
  "/i/telegram": {
    icon: <Telegram color={"#ffffff"} size={16} />,
    title: "Telegram",
  },
};

const MenuButton = ({}) => {
  const { isMenuOpened, setIsMenuOpened, activeRoute } = useContext(AppContext);

  const { icon, title } = routeMap[activeRoute] || {};

  return (
    <div className={`${styles.container}`} style={{ minHeight: "0" }}>
      <Button
        onClick={() => {
          setIsMenuOpened(!isMenuOpened);
        }}
        variant="carded"
        style={{ width: "100%" }}
        className="d-flex align-items-center w-100"
      >
        <div
          className="d-flex w-100 justify-content-between"
          style={{ marginTop: "-1px" }}
        >
          <div>
            {/*<span className="lh-1 me-2">МЕНЮ</span>*/}
            <ArrowLeft
              className="me-1"
              size={18}
              color="white"
              style={{ marginTop: "-2px" }}
            />
          </div>
          <div>{title ? <span>{title}</span> : <span>Меню</span>}</div>
          <div className="d-flex justify-content-center align-items-center">
            <div
              style={{ height: "18px" }}
              className="d-flex align-items-center ms-2"
            >
              {icon}
            </div>
          </div>
        </div>
      </Button>
    </div>
  );
};

export default MenuButton;
