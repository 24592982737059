// API.js

import axios from "axios";
import { API_ROOT } from "../../const/config";

export const addTask = async ({ promptText }) => {
  const response = await axios.post(
    `${API_ROOT}/modules/tasks/add`,
    { promptText: promptText }, // This is the data payload
    {
      withCredentials: true, // Configuration object
    },
  );

  return response.data; // Return response data if needed
};

export const getInfo = async ({ uuid }) => {
  const response = await axios.get(
    `${API_ROOT}/modules/tasks/info?uuid=${uuid}`,
    {
      withCredentials: true, // Configuration object
    },
  );

  return response.data; // Return response data if needed
};

export const updateReminders = async (eventUUID, reminders) => {
  const response = await axios.post(
    `${API_ROOT}/modules/tasks/options/remind`,
    { eventUuid: eventUUID, reminders: reminders }, // This is the data payload
    {
      withCredentials: true, // Configuration object
    },
  );

  return response.data; // Return response data if needed
};

export const updatePriority = async (eventUUID, priority) => {
  const response = await axios.post(
    `${API_ROOT}/modules/tasks/options/priority`,
    { eventUuid: eventUUID, priority: priority }, // This is the data payload
    {
      withCredentials: true, // Configuration object
    },
  );

  return response.data; // Return response data if needed
};

export const processAction = async (eventUUID, action) => {
  const response = await axios.post(
    `${API_ROOT}/modules/tasks/action`,
    { eventUuid: eventUUID, action: action }, // This is the data payload
    {
      withCredentials: true, // Configuration object
    },
  );

  return response.data; // Return response data if needed
};

export const getTasksList = async (date = null) => {
  if (!date) {
    date = Date.now();
  }

  const timestamp = Math.floor(date / 1000);
  console.log(timestamp);

  const response = await axios.get(
    `${API_ROOT}/modules/tasks/list?timestamp=${timestamp}`,
    {
      withCredentials: true,
    },
  );

  // Sorting tasks with the following priority:
  // 1. Tasks with task.start as None (come first)
  // 2. Tasks sorted by start date (ascending)
  // 3. Completed tasks (isCompleted === true) come last
  const sortedTasks = response.data.sort((a, b) => {
    // Handle tasks with start as None first
    if (a.start === null && b.start !== null) return -1;
    if (a.start !== null && b.start === null) return 1;

    // Sort by start date if both have valid start dates
    if (a.start && b.start) {
      const dateComparison = new Date(a.start) - new Date(b.start);
      if (dateComparison !== 0) return dateComparison;
    }

    // Place completed tasks at the end
    if (a.isCompleted && !b.isCompleted) return 1;
    if (!a.isCompleted && b.isCompleted) return -1;

    return 0; // If all else is equal
  });

  // Grouping tasks by category, using "0" for tasks without a category
  const tasksByCategory = sortedTasks.reduce((acc, task) => {
    const category = task.category || "0"; // Use "0" for tasks without a category
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(task);
    return acc;
  }, {});

  // Sorting categories, with "0" first (tasks without a category)
  const sortedCategories = Object.keys(tasksByCategory).sort((a, b) => {
    if (a === "0") return -1; // "0" should come first
    if (b === "0") return 1;
    return a.localeCompare(b);
  });

  return {
    tasksByCategory,
    sortedCategories,
  };
};

export const updateTitle = async (uuid, newTitle) => {
  const response = await axios.post(
    `${API_ROOT}/modules/tasks/options/title`,
    {
      eventUuid: uuid,
      title: newTitle,
    },
    {
      withCredentials: true,
    },
  );
  return response.data;
};
