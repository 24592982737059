import React, { createContext, useContext, useEffect, useState } from "react";
import { useNotification } from "./components/Notification/NotificationProvider";
import { WS_ROOT } from "./const/config";
import { useDispatch, useSelector } from "react-redux";
import { fetchTasks, highlightTask } from "./components/TaskManager/TaskSlice";
import {
  showOtpInput,
  telegramLoginFailed,
  telegramLoginSuccess,
  updateErrorHint,
  updateOtpHint,
} from "./components/TelegramClient/TelegramClientSlice";
import AppContext from "./context";

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
  const notify = useNotification();
  const [ws, setWs] = useState(null);
  const { isLogined } = useContext(AppContext);
  const dispatch = useDispatch();

  const { timestamp } = useSelector((state) => state.tasks);

  // WebSocket Connection Effect (Open/Close)
  useEffect(() => {
    let websocket;
    let reconnectTimeout;

    const connectWebSocket = () => {
      websocket = new WebSocket(`${WS_ROOT}/ws/tunnel`);

      websocket.onopen = () => {
        console.log("WebSocket connection opened");
        setWs(websocket);
      };

      websocket.onerror = (error) => {
        console.error("WebSocket error:", error);
        reconnectTimeout = setTimeout(connectWebSocket, 5000); // Reconnect after 5 seconds
      };

      websocket.onclose = (event) => {
        console.log("WebSocket connection closed", event);
        reconnectTimeout = setTimeout(connectWebSocket, 5000); // Reconnect after 5 seconds
      };
    };

    if (isLogined) {
      connectWebSocket();
    }

    return () => {
      if (websocket) {
        websocket.onclose = null; // Prevent reconnection during cleanup
        websocket.close();
      }
      clearTimeout(reconnectTimeout);
      console.log("Cleaning up WebSocket");
    };
  }, [isLogined]); // Only run this effect when `isLogined` changes

  // WebSocket Settings and Message Processing Effect
  useEffect(() => {
    if (ws) {
      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log("Received data", data);

        if (data.kind === "notification") {
          notify({
            message: data.payload,
          });
        } else if (data.kind === "action") {
          if (data.payload === "refreshTasks") {
            console.log("Refreshing tasks with timestamp:", timestamp);
            dispatch(fetchTasks(timestamp ? new Date(timestamp) : null));
          } else if (data.payload === "telegramShowOTP") {
            dispatch(showOtpInput());
          } else if (data.payload === "telegramLoginSuccess") {
            dispatch(telegramLoginSuccess());
          } else if (data.payload === "telegramLoginFailed") {
            dispatch(telegramLoginFailed(data.payload.errorMessage));
          }
        } else if (data.kind === "update_data") {
          if (data.payload.telegramTwoAuthHint) {
            dispatch(updateOtpHint(data.payload.telegramTwoAuthHint));
          } else if (data.payload.errorHint) {
            dispatch(updateErrorHint(data.payload.errorHint));
          } else if (data.payload.highlightTask) {
            dispatch(highlightTask(data.payload.highlightTask));
          }
        }
      };

      // Clean up WebSocket settings on component unmount
      return () => {
        if (ws) {
          ws.onmessage = null;
        }
      };
    }
  }, [ws, timestamp, notify, dispatch]); // Run this effect when ws or timestamp changes

  return (
    <WebSocketContext.Provider value={ws}>{children}</WebSocketContext.Provider>
  );
};

export const useWebSocketContext = () => {
  return useContext(WebSocketContext);
};
