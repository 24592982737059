import "./App.css";
import Home from "./pages/Home/Home";
import { NotificationProvider } from "./components/Notification/NotificationProvider";
import AppContext from "./context";
import React, { useMemo, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login/Login";
import { Provider } from "react-redux";
import store from "./store";
import { WebSocketProvider } from "./WSTunnel";
import ConnectionStatus from "./components/ConnectionStatus/ConnectionStatus";
import TaskInvite from "./components/TaskManager/TaskInvite";
import { handleLogin } from "./pages/Login/API";

function App() {
  const [isLogined, setIsLogined] = useState(null);
  const [isTelegramLogined, setIsTelegramLogined] = useState(null);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [activeRoute, setActiveRoute] = useState(null); // Default route

  useMemo(() => {
    handleLogin().then((response) => {
      console.log(response);
      if (response.isLogined === true) {
        setIsLogined(true);
      } else {
        setIsLogined(false);
      }
    });
  }, []);

  useMemo(() => {
    setActiveRoute(window.location.pathname);
  }, [window]);

  return (
    <Provider store={store}>
      <AppContext.Provider
        value={{
          isLogined: isLogined,
          setIsLogined: setIsLogined,
          isTelegramLogined: isTelegramLogined,
          setIsTelegramLogined: setIsTelegramLogined,
          isMenuOpened: isMenuOpened,
          setIsMenuOpened: setIsMenuOpened,
          activeRoute: activeRoute,
          setActiveRoute: setActiveRoute,
        }}
      >
        <NotificationProvider>
          <WebSocketProvider>
            <ConnectionStatus>
              <div className="App">
                <Router>
                  <Routes>
                    <Route path="*" element={<Home />} />
                    <Route path="m/tasks/invite" element={<TaskInvite />} />
                    <Route path="/login" element={<Login />} />
                  </Routes>
                </Router>
              </div>
            </ConnectionStatus>
          </WebSocketProvider>
        </NotificationProvider>
      </AppContext.Provider>
    </Provider>
  );
}

export default App;
