import React, { useContext, useMemo } from "react";

import styles from "./TelegramClient.module.css";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { CheckCircleFill, ExclamationTriangle } from "react-bootstrap-icons";
import Spinner from "../Spinner/Spinner";
import AppContext from "../../context";
import { isTelegramLoginedAPI } from "./API";
import { Link } from "react-router-dom";

const TelegramClientButton = ({}) => {
  const {
    isTelegramLogined,
    setIsTelegramLogined,
    setIsMenuOpened,
    setActiveRoute,
  } = useContext(AppContext);

  useMemo(() => {
    isTelegramLoginedAPI().then((response) => {
      console.log(response);
      if (response.isLogined === true) {
        setIsTelegramLogined(true);
      } else {
        setIsTelegramLogined(false);
      }
    });
  }, []);

  return (
    <div className={`${styles.buttonContainer}`}>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip>
            <span>
              Используйте функции Айрнольда прямо в переписке с человеком
            </span>
          </Tooltip>
        }
      >
        <Button
          className={`${styles.button} ${isTelegramLogined ? styles.isLogined : styles.notLogined}`}
          as={Link}
          to="/i/telegram"
          onClick={(e) => {
            setIsMenuOpened(false);
            setActiveRoute("/i/telegram");
          }}
        >
          {isTelegramLogined === null ? (
            <Spinner size={"12px"} fontSize={"14px"} withBG={false} />
          ) : (
            <>
              {!isTelegramLogined ? (
                <>
                  <div
                    className={styles.containerLight}
                    style={{ "--icon-color": "#ffb701" }}
                  ></div>
                  <span>Подключите Телеграм</span>
                  <ExclamationTriangle
                    size={14}
                    className={styles.triangleLogo}
                  />{" "}
                </>
              ) : (
                <>
                  <div
                    className={styles.containerLight}
                    style={{ "--icon-color": "#30FF76" }}
                  ></div>
                  <span>Телеграм подключен</span>
                  <CheckCircleFill
                    size={14}
                    className={styles.checkCircle}
                  />{" "}
                </>
              )}
            </>
          )}
        </Button>
      </OverlayTrigger>
    </div>
  );
};

export default TelegramClientButton;
