import axios from "axios";
import { API_ROOT } from "../../const/config";

export async function handleLogin(preAuthToken = null) {
  try {
    const response = await axios.get(
      `${API_ROOT}/api/profile/login` +
        (preAuthToken
          ? `?pre_auth_token=${preAuthToken}`
          : `?timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`),
      { withCredentials: true },
    );
    return response.data;
  } catch (error) {
    return false;
  }
}

export async function handleLogout() {
  try {
    await axios.post(
      `${API_ROOT}/api/profile/logout`,
      {},
      { withCredentials: true },
    );
    console.log("Logout successful");

    // You can also add any further actions like redirecting to the login page
  } catch (error) {
    console.error("Error during logout:", error);
  }
}
