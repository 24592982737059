import React, { useContext, useEffect } from "react";
import SideBar from "../../components/SideBar/SideBar";
import TaskManagerScreen from "../../components/TaskManager/TaskManagerScreen";

import styles from "./Home.module.css";
import AppContext from "../../context";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import TelegramClientScreen from "../../components/TelegramClient/TelegramClientScreen";
import Spinner from "../../components/Spinner/Spinner";
import { isMobile } from "react-device-detect";
import MenuButton from "../../components/MenuButton/MenuButton";

const Home = () => {
  const navigate = useNavigate();

  const { isLogined, isMenuOpened } = useContext(AppContext);

  useEffect(() => {
    if (isLogined === false) {
      navigate("/login");
    } else if (isLogined === true && window.location.pathname === "/") {
      navigate("/m/tasks");
    }
  }, [isLogined, navigate]);

  const title = "Эй Айрнольд! — персональный ассистент";
  const description = "Персональный ассистент нового поколения";

  const showSideBar = (isMobile && isMenuOpened) || !isMobile;
  const showContentContainer = (isMobile && !isMenuOpened) || !isMobile;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />

        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content="/static/og.jpg" />
      </Helmet>

      {isLogined === null || isLogined === false ? (
        <Spinner />
      ) : (
        <div className="position-relative d-flex flex-row justify-content-center align-items-center w-100 h-100">
          <div
            className={`d-flex h-100 ${styles.sidebarContainer} ${
              showSideBar ? styles.fadeIn : styles.fadeOut
            }`}
          >
            <SideBar />
          </div>

          <div
            className={`${styles.contentContainer} ${
              showContentContainer ? styles.fadeIn : styles.fadeOut
            }`}
          >
            {isMobile && (
              <div className="mb-2 w-100">
                <MenuButton />
              </div>
            )}
            <div className="card-container">
              <Routes>
                <Route path="m/tasks" element={<TaskManagerScreen />} />
                <Route path="i/telegram" element={<TelegramClientScreen />} />
                <Route path="*" element={<TaskManagerScreen />} />
                {/* Add more routes as needed */}
              </Routes>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
