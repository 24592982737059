import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Plus } from "react-bootstrap-icons";
import styles from "./TaskManager.module.css";
import CalendarWidget, { formatDate } from "../CalendarWidget/CalendarWidget";
import { useNotification } from "../Notification/NotificationProvider";
import TaskItem from "../TaskItem/TaskItem";
import animationData from "../../assets/animations/cat.json";
import { Player } from "@lottiefiles/react-lottie-player";
import Spinner from "../Spinner/Spinner";
import { fetchTasks, setTimestamp } from "./TaskSlice";
import { useDispatch, useSelector } from "react-redux";
import { addTask } from "./API";
import TextInput from "../TextInput/TextInput";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const TaskManagerScreen = () => {
  const [isCreating, setIsCreating] = useState(false);
  const [taskPrompt, setTaskPrompt] = useState("");

  const {
    tasksByCategory,
    sortedCategories,
    loading,
    timestamp,
    highlightedTasks,
  } = useSelector((state) => state.tasks);

  const [animationClass, setAnimationClass] = useState(styles.slideIn);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTasks(timestamp));
  }, [dispatch]);

  const notify = useNotification();

  const hidePrompt = (cleanUp = true) => {
    setAnimationClass("slide-out");
    setTimeout(() => {
      if (cleanUp) {
        setTaskPrompt("");
      }
      setIsCreating(false);
    }, 200);
  };

  const handleCreateClick = () => {
    setIsCreating(true);
    setAnimationClass("slide-in");
    setTimeout(() => {
      setTaskPrompt("");
    }, 20);
  };

  const handleCircleCreateToggle = () => {
    if (!isCreating) {
      handleCreateClick();
    } else {
      hidePrompt();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      hidePrompt(false); // Close the prompt
      // Add logic to close any other UI elements as needed
    } else if (e.key === "Enter") {
      handleCreateClick(); // Open the prompt
    }
  };

  useEffect(() => {
    dispatch(setTimestamp(selectedDate.getTime()));
    dispatch(fetchTasks(selectedDate));
  }, [selectedDate]);

  useEffect(() => {
    // Attach keydown listener
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      // Clean up the event listener
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleSaveTask = async () => {
    try {
      notify({
        message: "Добавляю вашу задачу, один момент.",
      });
      await addTask({ promptText: taskPrompt });
    } catch (error) {
      console.error("Error saving task:", error);
      notify({
        message:
          "Ой-ой! Во время добавления задачи произошла ошибка. Уже чиним этот баг.",
      });
    }
    hidePrompt(); // Hide the prompt after successful save
  };

  return (
    <div className="d-flex flex-column position-relative h-100">
      <>
        <div
          className={`position-relative d-flex flex-row justify-content-between w-100 align-items-center ${styles.header}`}
        >
          {isCreating && (
            <TextInput
              value={taskPrompt}
              setValue={setTaskPrompt}
              onConfirmCB={handleSaveTask}
              placeholder={"Запиши на ноготочки на 18 декабря к 15 часам..."}
              animationClass={animationClass}
            />
          )}
          {/*{!isMobile && (*/}
          {/*  <Button onClick={handleCreateClick} variant="primary">*/}
          {/*    <Plus*/}
          {/*      className="me-1"*/}
          {/*      size={22}*/}
          {/*      color="white"*/}
          {/*      style={{ marginLeft: "-2px" }}*/}
          {/*    />*/}
          {/*    <span className="lh-1" style={{ marginBottom: "2px" }}>*/}
          {/*      Создать*/}
          {/*    </span>*/}
          {/*  </Button>*/}
          {/*)}*/}
          <div className="w-100">
            <CalendarWidget
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
            />
          </div>

          {/*{!isMobile && (*/}
          {/*  <OverlayTrigger*/}
          {/*    placement="bottom"*/}
          {/*    overlay={*/}
          {/*      <Tooltip>*/}
          {/*        <span>Показывает ваши задачи на сегодня</span>*/}
          {/*      </Tooltip>*/}
          {/*    }*/}
          {/*  >*/}
          {/*    <Button*/}
          {/*      onClick={() => {*/}
          {/*        setSelectedDate(new Date());*/}
          {/*      }}*/}
          {/*      disabled={selectedDate.getDate() === new Date().getDate()}*/}
          {/*      variant="outline"*/}
          {/*    >*/}
          {/*      <span className="lh-1">Что сегодня?</span>*/}
          {/*    </Button>*/}
          {/*  </OverlayTrigger>*/}
          {/*)}*/}
        </div>
      </>

      <div className={`mt-3 ${styles.tasksContainer}`}>
        <TransitionGroup className="transition-container">
          <CSSTransition key={loading} classNames="fade" timeout={300}>
            <>
              {loading === true && (
                <div className="d-flex justify-content-center align-items-center">
                  <Spinner withBG={false} />
                </div>
              )}
              {loading === false && (
                <>
                  {!sortedCategories.includes("0") && (
                    <div className="d-flex flex-column align-content-center justify-content-center my-5">
                      <Player
                        autoplay
                        loop
                        src={animationData}
                        style={{ width: "160px", height: "160px" }}
                      />
                      <h3 className="mt-4 mb-3" style={{ fontSize: "1.7rem" }}>
                        Уютная пустота...
                      </h3>
                      <p className="text-center" style={{ lineHeight: "1.3" }}>
                        на{" "}
                        {selectedDate.toDateString() ===
                        new Date().toDateString()
                          ? "сегодня"
                          : formatDate(selectedDate)}{" "}
                        задач не найдено,
                        <br />
                        <Button
                          onClick={handleCreateClick}
                          variant="text"
                          style={{
                            padding: "0",
                            marginTop: "-4px",
                          }}
                        >
                          <span style={{ color: "#FBB040", fontSize: "1rem" }}>
                            добавьте
                          </span>
                        </Button>{" "}
                        их прямо сейчас
                      </p>
                    </div>
                  )}
                  {sortedCategories.map((category, index) => (
                    <div key={index}>
                      {category !== "0" && (
                        <h5
                          style={{
                            textAlign: "left",
                            marginLeft: "4px",
                          }}
                          className="mt-4 mb-0"
                        >
                          {category}
                        </h5>
                      )}
                      <>
                        {tasksByCategory[category].map((task, index) => (
                          <div key={index}>
                            <TaskItem
                              id={task.id}
                              uuid={task.uuid}
                              kind={task.kind}
                              title={task.title}
                              start={task.start}
                              end={task.end}
                              reminders={task.reminders}
                              priority={task.priority}
                              isCompleted={task.isCompleted}
                              spectator={task.spectator}
                              spectatorUsers={task.spectatorUsers}
                              selectedDate={selectedDate}
                              isHighlighted={highlightedTasks.includes(
                                task.uuid,
                              )}
                            />
                          </div>
                        ))}
                      </>
                    </div>
                  ))}
                </>
              )}
            </>
          </CSSTransition>
        </TransitionGroup>
      </div>

      <div className={styles.plusButtonContainer}>
        <Button
          onClick={handleCircleCreateToggle}
          variant="circle"
          className={styles.plusButton}
        >
          <Plus
            size={64}
            color="white"
            className={`${styles.rotatedCircle} ${isCreating ? styles.rotate45 : ""}`}
          />
        </Button>
      </div>
    </div>
  );
};

export default TaskManagerScreen;
