import axios from "axios";
import { API_ROOT } from "../../const/config"; // Check if the user is logged into Telegram

// Check if the user is logged into Telegram
export const isTelegramLoginedAPI = async () => {
  try {
    const response = await axios.get(`${API_ROOT}/api/telegram/is-logined`, {
      withCredentials: true,
    });
    return response.data; // { isLogined: true/false }
  } catch (error) {
    console.error("Error checking Telegram login status:", error);
    throw error;
  }
};

// Get the QR code URL for Telegram login
export const getQrCode = async () => {
  try {
    const response = await axios.get(`${API_ROOT}/api/telegram/qr/get`, {
      withCredentials: true,
    });
    return response.data; // { qr_code_url: "https://..." }
  } catch (error) {
    console.error("Error getting Telegram QR code:", error);
    throw error;
  }
};

// Resolve the OTP (Two-Factor Authentication)
export const resolveOtp = async (otp) => {
  try {
    const response = await axios.post(
      `${API_ROOT}/api/telegram/otp/resolve`,
      { otp },
      {
        withCredentials: true,
      },
    );
    return response.data; // { message: "2FA resolved and session established" }
  } catch (error) {
    console.error("Error resolving Telegram OTP:", error);
    throw error;
  }
};
