import React, { useContext, useEffect, useState } from "react";

import AppContext from "../../context";
import styles from "./Login.module.css";
import { Helmet } from "react-helmet";
import { v4 as uuidv4 } from "uuid";
import { useLocalStorage } from "../../hooks/LocalStorage";
import { handleLogin } from "./API";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/Spinner/Spinner";
import { Button } from "react-bootstrap";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import arnoldLogo from "../../assets/logo.svg";
import NoInternet from "../../components/NoInternet/NoInternet";
import { BOT_NAME } from "../../const/config";

const Login = ({}) => {
  const navigate = useNavigate();

  const { isLogined, setIsLogined, setIsMenuOpened } = useContext(AppContext);
  const [isInternetConnected, setIsInternetConnected] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [preAuthToken, setPreAuthToken] = useLocalStorage(
    "preAuthToken",
    uuidv4().toUpperCase(),
  );

  console.log("LOGIN", isLogined);

  const handleButtonClick = () => {
    window.open(
      `https://t.me/${BOT_NAME}?start=pre_auth_token__${preAuthToken}`,
      "_blank",
    );
  };

  useEffect(() => {
    // Set isLoading to false once preAuthToken is generated and set
    if (preAuthToken) {
      setIsLoading(false);
    }

    const intervalId = setInterval(() => {
      if (preAuthToken) {
        handleLogin(preAuthToken).then((response) => {
          console.log(response);
          if (response?.isLogined === true) {
            localStorage.removeItem("preAuthToken");
            setIsLogined(true);
            navigate("/i/telegram");
            setIsMenuOpened(true);
            return;
          } else if (!response) {
            setIsInternetConnected(false);
          }
          setIsLogined(false);
        });
      }
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [preAuthToken, setIsLogined, navigate]);

  console.log("isLogined", isLogined);

  if (isInternetConnected === false) {
    return <NoInternet />;
  }

  const title = "Вход | Эй Айрнольд!";
  const description = "Войдите в личный кабинет за один клик";

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />

        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content="/static/og.jpg" />
      </Helmet>

      <div className={styles.loginFormContainer}>
        <TransitionGroup>
          <CSSTransition key={isLogined} classNames="fade" timeout={300}>
            {(isLogined || isLogined === null) && isLogined !== false ? (
              <Spinner />
            ) : (
              <div className={styles.loginForm}>
                <div style={{ margin: "4px" }}>
                  <div className="d-flex flex-row justify-content-between align-items-center w-100 ">
                    <h3 style={{ fontSize: "22px" }}>Войдите</h3>
                    <img height={"22px"} src={arnoldLogo} alt="arnold logo" />
                  </div>
                  <div className="d-flex flex-column">
                    <div className="my-4">
                      <div
                        className="text-center"
                        style={{ justifyContent: "flex-start" }}
                      >
                        <span style={{ color: "#fff" }}>
                          Чтобы продолжить, нажмите на жёлтую кнопку ниже
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="d-grid gap-2">
                    <div className="d-flex flex-row w-100">
                      <Button
                        type="button"
                        variant="filled"
                        className="px-0 w-100"
                        onClick={handleButtonClick}
                        disabled={isLogined || false}
                      >
                        <span className={styles.loginButtonText}>Войти</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </CSSTransition>
        </TransitionGroup>
      </div>
    </>
  );
};

export default Login;
