import React, { useEffect, useMemo, useState } from "react";
import SelectInput from "../SelectInput/SelectInput";

import healthIcon from "../../assets/icons/taskKind/health.svg";
import workIcon from "../../assets/icons/taskKind/work.svg";
import gameIcon from "../../assets/icons/taskKind/game.svg";
import studyIcon from "../../assets/icons/taskKind/study.svg";
import familyIcon from "../../assets/icons/taskKind/family.svg";
import homeIcon from "../../assets/icons/taskKind/home.svg";

import highPriority from "../../assets/icons/taskPriority/high.svg";
import mediumPriority from "../../assets/icons/taskPriority/medium.svg";
import lowPriority from "../../assets/icons/taskPriority/low.svg";
import {
  ArrowDownLeft,
  ArrowReturnLeft,
  BellSlashFill,
  GearFill,
  Link45deg,
  PencilFill,
  PersonFill,
  TrashFill,
} from "react-bootstrap-icons";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

import styles from "./TaskItem.module.css";
import {
  processAction,
  updatePriority,
  updateReminders,
  updateTitle,
} from "../TaskManager/API";
import { useNotification } from "../Notification/NotificationProvider";
import { partial } from "lodash";
import { useDispatch } from "react-redux";
import { setLoading } from "../TaskManager/TaskSlice";
import { isMobile } from "react-device-detect";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const priorityOptions = [
  { value: "high", label: "Высокий", icon: highPriority },
  { value: "medium", label: "Средний", icon: mediumPriority },
  { value: "low", label: "Низкий", icon: lowPriority },
];

const reminderOptions = [
  { value: "NOW", label: "1 C", longLabel: "за секунду до" },
  { value: "MINUTES_15", label: "15 М", longLabel: "за 15 минут до" },
  { value: "HOUR_1", label: "1 Ч", longLabel: "за 1 час до" },
  { value: "HOURS_2", label: "2 Ч", longLabel: "за 2 часа до" },
  { value: "HOURS_10", label: "10 Ч", longLabel: "за 10 часов до" },
  { value: "DAY_1", label: "1 Д", longLabel: "за 1 день до" },
  { value: "DAYS_2", label: "2 Д", longLabel: "за 2 дня до" },
  { value: "WEEK_1", label: "1 Н", longLabel: "за 1 неделю до" },
];

const icon2render = {
  health: { icon: healthIcon, color: "#FF7AAA" },
  work: { icon: workIcon, color: "#8C74EC" },
  game: { icon: gameIcon, color: "#EC9F74" },
  study: { icon: studyIcon, color: "#74ECC8" },
  family: { icon: familyIcon, color: "#ECE074" },
  home: { icon: homeIcon, color: "#74D6EC" },
};

const formatTime = (date) => {
  return date.toLocaleTimeString("ru-RU", {
    hour: "2-digit",
    minute: "2-digit",
  });
};

const DateInfo = ({ isToday, startDate, endDate, displayDate }) => {
  return (
    <div style={{ cursor: "default" }}>
      {isToday ? (
        <div>
          <div
            className={`${styles.infoContainer}`}
            style={isMobile ? { border: "none" } : {}}
          >
            <span>
              {formatTime(startDate)} - {formatTime(endDate)}
            </span>
          </div>
        </div>
      ) : (
        <div
          className={`${styles.infoContainer}`}
          style={isMobile ? { border: "none" } : {}}
        >
          <span>
            {displayDate} в {formatTime(startDate)}{" "}
          </span>
        </div>
      )}
    </div>
  );
};

const InviteButton = ({
  uuid,
  spectatorUsers,
  handleCopyInvite,
  inviteCopied,
}) => {
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip>
          {spectatorUsers && spectatorUsers.length > 0 && (
            <span>
              В событии участвуют: {spectatorUsers.join(", ")}
              <br />
              <br />
            </span>
          )}
          Чтобы пригласить друзей, нажмите на эту кнопку
        </Tooltip>
      }
    >
      <div
        className={`${styles.infoContainer}`}
        style={{ minWidth: "60px" }}
        onClick={() => {
          handleCopyInvite(
            `${window.location.origin}/m/tasks/invite?uuid=${uuid}`,
          );
        }}
      >
        {spectatorUsers && spectatorUsers.length > 0 ? (
          <span>
            <PersonFill
              color="#FBB040"
              size={16}
              className="me-2"
              style={{ margin: "-3px 0 0 -2px" }}
            />
            {spectatorUsers.length}
          </span>
        ) : (
          <Link45deg
            className={`${styles.inviteLinkLogo} ${
              inviteCopied ? styles.copied : ""
            }`}
            size={20}
            style={{ margin: "-2px 0 0 0" }}
          />
        )}
      </div>
    </OverlayTrigger>
  );
};

const TaskItem = ({
  id,
  uuid,
  kind,
  start,
  end,
  title,
  reminders,
  priority,
  isCompleted,
  spectator,
  spectatorUsers,
  selectedDate,
  isHighlighted,
}) => {
  const notify = useNotification();
  const dispatch = useDispatch();

  const [inviteCopied, setInviteCopied] = useState(false);

  const [showSettings, setShowSettings] = useState(true);

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [newTitle, setNewTitle] = useState(title);

  // Update newTitle when title prop changes
  useEffect(() => {
    setNewTitle(title);
  }, [title]);

  const { icon, color } = icon2render[kind] || { icon: null, color: "#000" };

  const now = selectedDate;
  const startDate = start && new Date(start);
  const endDate = end && new Date(end);

  const isToday =
    !start ||
    startDate.toDateString() === now.toDateString() ||
    endDate.toDateString() === now.toDateString();

  const displayDate =
    !isToday &&
    startDate.toLocaleDateString("ru-RU", {
      day: "numeric",
      month: "long",
    });

  const handleAction = async (eventUUID, action) => {
    dispatch(setLoading(true));
    try {
      await processAction(eventUUID, action);
    } catch (error) {
      console.error("Error saving task:", error);
      notify({
        message:
          "Ой-ой! Во время обновления задачи произошла ошибка. Уже чиним этот баг.",
      });
    }
  };

  const handleReminders = async (eventUUID, reminders) => {
    try {
      await updateReminders(eventUUID, reminders);
    } catch (error) {
      console.error("Error saving task:", error);
      notify({
        message:
          "Ой-ой! Во время обновления напоминаний задачи произошла ошибка. Уже чиним этот баг.",
      });
    }
  };

  const handlePriority = async (eventUUID, priorities) => {
    try {
      await updatePriority(eventUUID, priorities[0]);
    } catch (error) {
      console.error("Error saving task:", error);
      notify({
        message:
          "Ой-ой! Во время обновления приоритетов задачи произошла ошибка. Уже чиним этот баг.",
      });
    }
  };

  const handleCopyInvite = (stringToCopy) => {
    navigator.clipboard.writeText(stringToCopy).then(() => {
      setInviteCopied(true);
      notify({
        message: "Ссылка на событие скопирована.",
      });
      setTimeout(() => setInviteCopied(false), 5000); // Reset the copied state after 5 seconds
    });
  };

  const handleStartEditing = (e) => {
    e.stopPropagation(); // Prevent triggering parent click events
    if (!spectator) {
      setIsEditingTitle(true);
    }
  };

  const handleTitleChange = (e) => {
    setNewTitle(e.target.value);
  };

  const handleTitleSubmit = async () => {
    if (newTitle.trim() === "") {
      notify({ message: "Название задачи не может быть пустым." });
      return;
    }

    try {
      await updateTitle(uuid, newTitle.trim());
      setIsEditingTitle(false);
      // Dispatch action to update title in Redux store
      // dispatch(updateTaskTitle({ uuid, title: newTitle.trim() }));
    } catch (error) {
      console.error("Error updating title:", error);
      notify({
        message:
          "Ой-ой! Во время обновления названия задачи произошла ошибка. Уже чиним этот баг.",
      });
    }
  };

  const handleKeyDown = (e) => {
    e.stopPropagation();
    if (e.key === "Enter") {
      e.preventDefault();
      handleTitleSubmit();
    } else if (e.key === "Escape") {
      setIsEditingTitle(false);
      setNewTitle(title); // Reset to original title if editing is canceled
    }
  };

  useMemo(() => {
    if (isMobile) {
      setShowSettings(false);
    }
  }, [isMobile]);

  return (
    <div
      className={`d-flex flex-row justify-content-between align-items-center ${styles.container} flex-nowrap`}
    >
      <div
        className={`${styles.containerLight} ${
          isCompleted ? styles.isCompleted : ""
        } ${isHighlighted ? styles.pulseHighlight : ""}`}
        style={{ "--icon-color": color }}
      />
      <div
        className={`d-flex flex-row justify-content-between align-items-center ${styles.container} ${styles.child}`}
      >
        <div
          className={`d-flex flex-row justify-content-start align-items-center ${styles.taskInfoContainer}`}
        >
          <div className={styles.iconContainer}>
            <img alt={`${kind} icon`} src={icon} />
          </div>
          <div
            className={`d-flex align-items-center ${styles.titleContainer}`}
            style={{ cursor: spectator ? "default" : "pointer" }}
            onClick={handleStartEditing}
          >
            {isEditingTitle ? (
              <input
                type="text"
                value={newTitle}
                onChange={handleTitleChange}
                onBlur={handleTitleSubmit}
                onKeyDown={handleKeyDown}
                className={styles.titleInput}
                autoFocus
              />
            ) : (
              <>
                <span
                  className={`${styles.title} ${
                    isCompleted ? styles.isCompleted : ""
                  }`}
                >
                  {newTitle}
                </span>
                {!spectator && (
                  <PencilFill size={14} className={styles.pencilIcon} />
                )}
              </>
            )}
          </div>
        </div>
        {startDate && !isMobile && (
          <div
            className={`d-flex flex-row justify-content-center align-items-center ${styles.datetimeContainer} my-1`}
          >
            <DateInfo
              displayDate={displayDate}
              isToday={isToday}
              startDate={startDate}
              endDate={endDate}
            />
            <div className="ms-2">
              <InviteButton
                uuid={uuid}
                spectatorUsers={spectatorUsers}
                handleCopyInvite={handleCopyInvite}
                inviteCopied={inviteCopied}
              />
            </div>
          </div>
        )}

        <div
          className={`d-flex flex-row justify-content-end align-items-center my-1 ${styles.taskActiveInfoContainer}`}
        >
          <CSSTransition
            in={showSettings}
            timeout={300}
            classNames={{
              enter: styles.secondRowEnter,
              enterActive: styles.secondRowEnterActive,
              exit: styles.secondRowExit,
              exitActive: styles.secondRowExitActive,
            }}
            unmountOnExit
          >
            <div
              className={`d-flex flex-row justify-content-start align-items-center ${styles.taskOptionsContainer}`}
            >
              {!isCompleted && (
                <>
                  <div className={styles.secondRow}>
                    {isMobile && (
                      <div className="me-2 w-100">
                        <InviteButton
                          uuid={uuid}
                          spectatorUsers={spectatorUsers}
                          handleCopyInvite={handleCopyInvite}
                          inviteCopied={inviteCopied}
                        />
                      </div>
                    )}
                    <div className="me-2 w-100">
                      <SelectInput
                        options={priorityOptions}
                        defaultValues={[priority]}
                        title={"Приоритет"}
                        isMulti={false}
                        onUpdateCB={partial(handlePriority, uuid)}
                        disabled={spectator}
                      />
                    </div>
                    <div className={`${isMobile ? "me-3" : ""} w-100`}>
                      <SelectInput
                        options={reminderOptions}
                        defaultValues={reminders}
                        title={"Напомнить"}
                        isMulti={true}
                        minWidth={"74px"}
                        deselectAllLogo={<BellSlashFill size={15} />}
                        onUpdateCB={partial(handleReminders, uuid)}
                        disabled={!start || spectator}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </CSSTransition>
          {!isMobile && (
            <div
              className={`d-flex flex-row align-items-center justify-content-end ${styles.actionsContainer}`}
            >
              <OverlayTrigger
                overlay={
                  <Tooltip>
                    {spectator ? (
                      <span>Отписаться от события</span>
                    ) : (
                      <>
                        {!isCompleted ? (
                          <span>Удалить событие</span>
                        ) : (
                          <span>Скрыть событие</span>
                        )}
                      </>
                    )}
                  </Tooltip>
                }
              >
                <Button
                  onClick={() => {
                    handleAction(uuid, "cancel");
                  }}
                  variant="text"
                  className={styles.actionButton}
                >
                  <div className={styles.actionImageContainer}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        className={styles.cancelLogo}
                        fill="#9CA0A1"
                        d="M.282.563a.96.96 0 0 1 1.359 0l5.078 5.08 5.078-5.08a.96.96 0 1 1 1.36 1.359L8.075 7l5.08 5.079a.962.962 0 0 1-1.359 1.358L6.72 8.357l-5.078 5.08A.96.96 0 1 1 .28 12.08L5.363 7 .282 1.922a.96.96 0 0 1 0-1.359Z"
                      />
                    </svg>
                  </div>
                </Button>
              </OverlayTrigger>
              {isCompleted && (
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      <span>Восстановить событие</span>
                    </Tooltip>
                  }
                >
                  <Button
                    onClick={() => {
                      handleAction(uuid, "uncomplete");
                    }}
                    variant="text"
                    className={styles.actionButton}
                  >
                    <div className={styles.actionImageContainer}>
                      <svg
                        id="Layer_1"
                        data-name="Layer 1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 500 500"
                      >
                        <path
                          className={styles.repeatLogo}
                          fill="#9CA0A1"
                          d="M470.48,33.94c-16.27,0-29.5,13.23-29.5,29.5v162.8c0,21.14-17.2,38.34-38.34,38.34H100.77l85.32-85.3c11.51-11.51,11.51-30.24,0-41.75-5.58-5.58-12.99-8.65-20.87-8.65s-15.3,3.07-20.87,8.65L8.74,273.14s-.07.07-.11.1c-11.49,11.52-11.46,30.23.04,41.71l142.45,142.45c5.58,5.58,12.99,8.65,20.87,8.65s15.3-3.07,20.87-8.65c5.58-5.58,8.65-12.99,8.65-20.88s-3.07-15.3-8.65-20.88l-92.11-92.08h301.87c53.67,0,97.33-43.66,97.33-97.33V63.44c0-16.27-13.23-29.5-29.5-29.5Z"
                        />
                      </svg>
                    </div>
                  </Button>
                </OverlayTrigger>
              )}
              {!isCompleted && !spectator && (
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      {spectator ? (
                        <span>Отписаться от события</span>
                      ) : (
                        <span>Завершить событие</span>
                      )}
                    </Tooltip>
                  }
                >
                  <Button
                    onClick={() => {
                      handleAction(uuid, "complete");
                    }}
                    variant="text"
                    className={`${styles.actionButton}`}
                  >
                    <div className={styles.actionImageContainer}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="11"
                        fill="none"
                        viewBox="0 0 15 11"
                      >
                        <path
                          fill="#30FF76"
                          d="M13.276.285a1.046 1.046 0 0 1 1.428 0c.39.374.395.978.013 1.359L6.654 10.69a.998.998 0 0 1-.327.225 1.043 1.043 0 0 1-1.125-.199L.295 5.997A.955.955 0 0 1 0 5.311c0-.256.106-.502.295-.686a1.046 1.046 0 0 1 1.428 0l4.162 4.003L13.249.313l.027-.028Z"
                        />
                      </svg>
                    </div>
                  </Button>
                </OverlayTrigger>
              )}
            </div>
          )}
        </div>
        {isMobile && (
          <div
            className={`d-flex flex-row align-items-center justify-content-start ${styles.actionsContainer} my-1`}
          >
            {!isCompleted && !spectator && (
              <Button
                onClick={() => setShowSettings(!showSettings)}
                variant="text"
                className={`${styles.actionButton} ${styles.infoContainer} mx-0 me-2`}
                style={{ minWidth: 0, maxWidth: "20%", borderRadius: "12px" }}
              >
                <div className={styles.actionImageContainer}>
                  <GearFill
                    size={20}
                    color="#ffffff"
                    className={`${styles.gearIcon} ${showSettings ? styles.gearIconActive : ""}`}
                  />
                </div>
              </Button>
            )}
            {startDate && (
              <DateInfo
                displayDate={displayDate}
                isToday={isToday}
                startDate={startDate}
                endDate={endDate}
              />
            )}
          </div>
        )}
      </div>

      {isMobile && (
        <div className="d-flex flex-column justify-content-center align-items-center">
          {isCompleted || spectator ? (
            <>
              <Button
                onClick={() => {
                  handleAction(uuid, "cancel");
                }}
                variant="text"
                className={`${styles.actionButton} ${styles.infoContainer} mx-0`}
                // style={{ borderColor: "#ff563094" }}
              >
                <div className={styles.actionImageContainer}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      className={styles.cancelLogo}
                      fill="#FF5630"
                      d="M.282.563a.96.96 0 0 1 1.359 0l5.078 5.08 5.078-5.08a.96.96 0 1 1 1.36 1.359L8.075 7l5.08 5.079a.962.962 0 0 1-1.359 1.358L6.72 8.357l-5.078 5.08A.96.96 0 1 1 .28 12.08L5.363 7 .282 1.922a.96.96 0 0 1 0-1.359Z"
                    />
                  </svg>
                </div>
                {/*{spectator ? (*/}
                {/*  <span>Отписаться от события</span>*/}
                {/*) : (*/}
                {/*  // <span>Скрыть</span>*/}
                {/*)}*/}
              </Button>

              {!spectator && (
                <Button
                  onClick={() => {
                    handleAction(uuid, "uncomplete");
                  }}
                  variant="text"
                  // style={{ borderColor: "#FFAB00" }}
                  className={`${styles.actionButton} ${styles.infoContainer} mx-0 mt-2`}
                >
                  <div className={styles.actionImageContainer}>
                    <svg
                      id="Layer_1"
                      data-name="Layer 1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 500 500"
                    >
                      <path
                        className={styles.repeatLogo}
                        fill="#FFAB00"
                        d="M470.48,33.94c-16.27,0-29.5,13.23-29.5,29.5v162.8c0,21.14-17.2,38.34-38.34,38.34H100.77l85.32-85.3c11.51-11.51,11.51-30.24,0-41.75-5.58-5.58-12.99-8.65-20.87-8.65s-15.3,3.07-20.87,8.65L8.74,273.14s-.07.07-.11.1c-11.49,11.52-11.46,30.23.04,41.71l142.45,142.45c5.58,5.58,12.99,8.65,20.87,8.65s15.3-3.07,20.87-8.65c5.58-5.58,8.65-12.99,8.65-20.88s-3.07-15.3-8.65-20.88l-92.11-92.08h301.87c53.67,0,97.33-43.66,97.33-97.33V63.44c0-16.27-13.23-29.5-29.5-29.5Z"
                      />
                    </svg>
                  </div>
                  {/*<span>Восстановить</span>*/}
                </Button>
              )}
            </>
          ) : (
            <Button
              onClick={() => {
                handleAction(uuid, !spectator ? "complete" : "cancel");
              }}
              variant="text"
              className={`${styles.actionButton} ${styles.infoContainer} mx-0`}
            >
              <div className={styles.actionImageContainer}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="11"
                  fill="#30FF76"
                  viewBox="0 0 15 11"
                >
                  <path
                    fill="#30FF76"
                    d="M13.276.285a1.046 1.046 0 0 1 1.428 0c.39.374.395.978.013 1.359L6.654 10.69a.998.998 0 0 1-.327.225 1.043 1.043 0 0 1-1.125-.199L.295 5.997A.955.955 0 0 1 0 5.311c0-.256.106-.502.295-.686a1.046 1.046 0 0 1 1.428 0l4.162 4.003L13.249.313l.027-.028Z"
                  />
                </svg>
              </div>
              {/*<span className="ms-2">Завершить</span>*/}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default TaskItem;
