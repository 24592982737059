import React, { useContext } from "react";

import arnoldLogo from "../../assets/logo.svg";
import tasksLogo from "../../assets/icons/tasks.svg";

import { Nav, OverlayTrigger, Tooltip } from "react-bootstrap";

import styles from "./SideBar.module.css";
import UserProfile from "../UserProfile/UserProfile";
import { Chat } from "react-bootstrap-icons";
import TelegramClientButton from "../TelegramClient/TelegramClientButton";
import { NavLink } from "react-router-dom";
import AppContext from "../../context"; // Import NavLink from react-router-dom

const SideBar = () => {
  const { setIsMenuOpened, setActiveRoute } = useContext(AppContext);
  return (
    <div
      className={`d-flex flex-column justify-content-between card-container`}
    >
      <div className="d-flex flex-column">
        <div
          className="d-flex flex-row justify-content-start align-items-center w-100"
          style={{ marginBottom: "1.52rem" }}
        >
          <img
            className="me-3"
            loading="lazy"
            src={arnoldLogo}
            alt="airnold logo"
            width={78}
          />
          <div className="d-flex flex-column justify-content-center align-items-start">
            <h2 className={`${styles.assistantTitle}`}>Эй Айрнольд!</h2>
            <p className={`${styles.assistantSubTitle}`}>
              персональный ассистент
            </p>
          </div>
        </div>

        <Nav className="flex-column">
          <Nav.Link
            as={NavLink}
            to="/m/tasks"
            onClick={(e) => {
              setIsMenuOpened(false);
              setActiveRoute("/m/tasks");
            }}
            className="nav-link"
          >
            <img src={tasksLogo} alt="Tasks" />
            <span>Задачи</span>
          </Nav.Link>
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip>
                <span>Общение с Айрнольдом уже в разработке!</span>
              </Tooltip>
            }
          >
            <Nav.Link disabled={false} className="nav-link">
              <Chat
                color={"#979797"}
                style={{ margin: "-2px 12px 0 2px" }}
                size={20}
              />
              <span style={{ color: "#979797" }}>Чатик</span>
            </Nav.Link>
          </OverlayTrigger>
        </Nav>
      </div>
      <div className="mx-2">
        <TelegramClientButton />
        <UserProfile />
      </div>
    </div>
  );
};

export default SideBar;
